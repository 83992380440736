import './Promo.css';


function Promo() {
  return (
    <section className='promo'>

      <div className='promo__cont'>
        <div className='promo__texts'>
          <h1 className='promo__title'>Качественные натяжные потолки за 1 день с гарантией</h1>
          <ul className='promo__list'>
            <li className='promo__li'>За 5 лет установили потолки более 1000 клиентам</li>
            <li className='promo__li'>Прямые поставщики материалов</li>
            <li className='promo__li'>Работаем только с сертифицированным материалом</li>
          </ul>
        </div>
      {/* */}<div className='promo__photo'></div> 
      </div>
    </section>
  )
}

export default Promo;