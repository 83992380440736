
import './Main.css';
import Promo from '../Promo/Promo';
import Steps from '../Steps/Steps';
import Tools from '../Tools/Tools';
import NavTab from '../NavTab/NavTab';
import Info from '../Info/Info';
import Lightbox from '../Lightbox/Lightbox';
function Main(props) {

  return (
    <main className='main'>
      <Promo />
      <Steps />
      <Lightbox/>
      <Tools />
      <Info />
    </main>
  )
}

export default Main;