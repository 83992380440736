import './Tools.css';

function toolsMe() {
  return (
    <section className='tools'>
      <div className='tools__container'>
        <h2 className='tools__title'>Используем только профессиональный инструмент. Гарантируем чистоту после проведения работ.</h2>
        <ul className='tools__list'>
          <li className='tools__item'>
            <div className='tools__img tools__perf'></div>
            <p className='tools__text'>Перфоратор с пылесосом,
            позволяет свести к минимуму пыль во время работ.
            </p>
          </li>
          <li className='tools__item'>
            <div className='tools__img tools__gvozd'></div>
            <p className='tools__text'>Благодаря газовому гвоздезабивному пистолету
            нет необходимости сверлить стены.</p>
          </li>
          <li className='tools__item'>
            <div className='tools__img tools__lazer'></div>
            <p className='tools__text'>Используя лазерный нивелир,
            все работы выполняются строго по уровню.</p>
          </li>
          <li className='tools__item'>
            <div className='tools__img tools__gaz'></div>
            <p className='tools__text'>Композитные газовые баллоны обеспечивают
            100% безопастность.</p>
          </li>
          
        </ul>
      </div>
    </section>
  )
}

export default toolsMe;