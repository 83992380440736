import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
        <header className='header'>
          <div className='header__container'>
            <Link to='/' className='header__logo' />
            <div className='header__text'>Установка и ремонт любых видов натяжных потолков в Москве и Московской области</div>
            <div className='header__contact'>
            <div className='header__links'>
              <Link className='header__link header__w'  title="WhatsApp" target="_blank" to="//api.whatsapp.com/send?phone=79777006606"/>
              <Link className='header__link header__t' title="Телеграмм" target="_blank" to="//msngr.link/tg/@vsem_potolok"/>
              <Link className='header__link header__vk' title="ВКонтакте" target="_blank" to="https://vk.com/public219989938"/>
              <Link className='header__link header__us' title="Яндекс Услуги" target="_blank" to="https://uslugi.yandex.ru/profile/DenisSmirnov-2269525"/>
              
            </div>
            <Link className='header__tel' target="_blank" to="tel:+79777006606">+7 977 700 66 06</Link>
         </div>
          </div>
        </header> 
  )
}

export default Header;