import { Link } from 'react-router-dom';
import './Steps.css';

function Steps() {
  return (
    <section className='steps'>
      <div className='steps__container'>
        <h2 className='steps__title'>В 90% случаев устанавливаем потолки за 1 день</h2>
        <ul className='steps__list'>
          <li className='steps__item'>
            <div className='steps__icon'>
              <div className='steps__num'>1</div>
              <div className='steps__img steps__of'></div>
            </div>
            <p className='steps__text'>Оформляете заявку   
            <Link className='steps__tel' target="_blank" to="tel:+79777006606"> +7(977)700 66 06</Link>
            </p>
            
          </li>
          <li className='steps__item'>
          <div className='steps__icon'>
              <div className='steps__num'>2</div>
              <div className='steps__img steps__zam'></div>
            </div>
            <p className='steps__text'>В оговоренные сроки выполняем замер, составляем смету</p>
          </li>
          <li className='steps__item'>
          <div className='steps__icon'>
              <div className='steps__num'>3</div>
              <div className='steps__img steps__dog'></div>
            </div>
            <p className='steps__text'>Заключаем договор, в котором фиксируем сроки и цену</p>
          </li>
          <li className='steps__item'>
          <div className='steps__icon'>
              <div className='steps__num'>4</div>
              <div className='steps__img steps__set'></div>
            </div>
            <p className='steps__text'>Устанаваливаем потолки и убираем за собой</p>
          </li>
          <li className='steps__item'>
          <div className='steps__icon'>
              <div className='steps__num'>5</div>
              <div className='steps__img steps__fin'></div>
            </div>
            <p className='steps__text'>Готовый результат</p>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Steps;