import './Footer.css';
import { Link } from 'react-router-dom';
function Footer() {
  return (
    <footer className='footer'>
      <div className='footer__container'>
        <h2 className='footer__title'>Остались вопросы?
        </h2>
        <p className='footer__subtitle'>Свяжитесь с нами любым удобным образом, чтобы уточнить все детали монтажа и стоимости натяжных потолков.</p>
        <div className='footer__block'>
          <p className='footer__year'>&copy; 2023 “Nat-Pot.ru” - установка и ремонт натяжных потолков в Москве и Московской области</p>
          <div className='header__contact'>
          <div className='header__links'>
            <Link className='header__link header__w' title="Whatsapp" target="_blank" to="//api.whatsapp.com/send?phone=79777006606" />
            <Link className='header__link header__t' title="Телеграмм" target="_blank" to="//msngr.link/tg/@vsem_potolok" />
            <Link className='header__link header__vk' title="ВКонтакте" target="_blank" to="https://vk.com/public219989938"/>
            <Link className='header__link header__us' title="Яндекс Услуги" target="_blank" to="https://uslugi.yandex.ru/profile/DenisSmirnov-2269525" />
          </div>
          <Link className='header__tel' target="_blank" to="tel:+79777006606">+7 977 700 66 06</Link>
       </div> </div>
      </div>
    </footer>
  )
}

export default Footer;