import './Info.css';

function Info() {
  return (
    <section className='info' id="info">
      <div className='info__container'>
        <h2 className='info__title'>Наши потолки полностью соответствуют требованиям безопасности:</h2>

<details>
    <summary className='info__subtitle'>Пожаробезопасны</summary>
    <p className='info__text'>Короткое замыкание в проводке не приведет к пожару в доме</p>
</details>
<details>
    <summary className='info__subtitle'>Не вызывают аллергии</summary>
    <p className='info__text'>Вы и ваши близкие не будуте страдать от вредных выделений, все полотна гипоаллергенны. </p>
</details>
<details>
    <summary className='info__subtitle'>Снижают уровень шума</summary>
    <p className='info__text'>Дополнительная воздушная прослойка от соседей, что скажется на уровне комфорта</p>
</details>
<details>
    <summary className='info__subtitle'>Не издают и не впитывают запахи</summary>
    <p className='info__text'>В отличие от низкокачественных потолков</p>
</details>
<details>
    <summary className='info__subtitle'>Не боятся влаги</summary>
    <p className='info__text'>Легко выдерживают вес воды, спасая жилье в случае потопа</p>
</details>

</div>

     </section>




  )
}

export default Info;