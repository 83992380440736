import React from "react";
import "./Lightbox.css"

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";


import { useEffect, useState } from 'react';
import useLookWindowSize from "../../hooks/useLookWindowSize";



//IMAGES
//you can also import a local file, the syntax would look like:
//import image1 from './images/imagename.jpg'
const image1 =
  require("../../images/foto/Image15.jpg");
const image2 =
require("../../images/foto/Image21.jpg");
const image3 =
require("../../images/foto/Image19.jpg");
const image4 =
require("../../images/foto/Image3.jpg");
const image5 =
require("../../images/foto/Image17.jpg");
const image6 =
require("../../images/foto/Image14.jpg");
const image7 =
require("../../images/foto/Image22.jpg");
const image8 =
require("../../images/foto/Image8.jpg");
const image9 =
require("../../images/foto/Image10.jpg");
const image10 =
require("../../images/foto/Image5.jpg");
const image11 =
require("../../images/foto/Image18.jpg");
const image12 =
require("../../images/foto/Image6.jpg");
const image13 =
require("../../images/foto/Image23.jpg");

//IMAGE ARRAY
const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13 ];

//MAIN APP COMPONENT
export default function Lightbox() {

    

  return (
    <div className="lightbox-conteiner">
      <h1 className="lightbox-title">Наши работы</h1>
      <ImageGallery />
    </div>
  );
}


//MAIN LIGHTBOX
//Holds Images Cards and Lightbox
//this is where all of our logic will live
function ImageGallery() {
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  const { width } = useLookWindowSize() // хук ширины экрана
  const [slidesPerView, setSlidesPerView] = useState({});
  //function to show a specific image in the lightbox, amd make lightbox visible
  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
  };

  //hide lightbox
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  //show next image in lightbox
  const showNext = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex >= images.length - 1) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex + 1];
      setImageToShow(nextImage);
    }
  };

  //show previous image in lightbox
  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex <= 0) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex - 1];
      setImageToShow(nextImage);
    }
  };

  useEffect(() => {
    if (width >= 1280) {
        setSlidesPerView(4);
    } else if (width >= 1000 && width < 1280) {
        setSlidesPerView(3);
    } else if (width >= 600 && width < 1000) {
        setSlidesPerView(2);
    } else if (width <= 600) {
        setSlidesPerView(1);
    }
  }, [width]);
  

  return (
    <>
     <Swiper      
        slidesPerView={slidesPerView} // сколько изначально
        centeredSlides={false} // первый слайд в центре
        spaceBetween={30} // растояние между
        pagination={{clickable: true}}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        
        
        {images.map((img, i) => {
          return (
            <SwiperSlide key={i}>
              <img className="lightbox__swiper-slide" src={img} alt="" onClick={() => showImage(img)} />
            </SwiperSlide>
            
          );
        })}

      </Swiper>
      { lightboxDisplay ? 
        <div className="lightbox__popup" onClick={hideLightBox}>
          <button className="swiper-button-prev" onClick={showPrev}/>
          <img className="lightbox-img_background" src={imageToShow} alt=""></img>
          <button className="swiper-button-next" onClick={showNext}/>
        </div>
       : "" }
    </>
  );
}

